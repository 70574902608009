import Vue from "vue";
import axios from "axios";
import Store from "@/store";
import Router from "@/router";

let config = {
  baseURL: process.env.VUE_APP_API_PATH || "http://localhost:8000/api"
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    const code = error.response.status;
    if (code === 401 && error.response.data.error === "access_denied") {
      Store.dispatch("LOGOUT");
      Vue.axios.defaults.headers.common.Authorization = "";
      Router.push({ name: "login", query: { status: "not_confirmed" } });
      return Promise.reject(error);
    } else if (code === 401 && localStorage.getItem("refresh_token")) {
      return Vue.axios
        .put("auth/refresh", {
          refresh_token: localStorage.getItem("refresh_token")
        })
        .then(res => {
          const data = res.data;
          localStorage.setItem("auth_token", data.auth_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          Vue.axios.defaults.headers.common.Authorization = `${data.auth_token}`;
          error.config.headers.Authorization = `${data.auth_token}`;
          Store.commit("SET_TOKEN", data);
          return Vue.axios(error.config);
        })
        .catch(e => {
          Store.dispatch("LOGOUT");
          Vue.axios.defaults.headers.common.Authorization = "";
          Router.push({ name: "login" });
          return Promise.reject(error);
        });
    } else if (code === 404) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
